.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  padding-left: 25%;
  padding-right: 25%;
}

.flex-container {
  background-color: #ccdbf9;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%; /* Adds 10% margin on both sides */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Add 12 pixels of margin between each <tr> */
tr {
  margin-bottom: 12px; /* For a buffer between rows */
  display: block; /* Makes margin work on tr (since <tr> is display: table-row by default) */
}

/* Alternatively, add padding to <td> elements inside <tr> */
td {
  padding-bottom: 12px; /* Adds spacing between rows */
}

.App-link {
  color: #61dafb;
}

/* Keyframes for rotating logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.date-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Adds space between the date input and button */
    margin-top: 20px; /* Optional: add space above the date entry row */
}

/* New Styles for Larger Input and Buttons */
.large-input {
  font-size: 1.5em; /* Doubles the font size */
  padding: 10px;    /* Increases padding for a larger appearance */
  width: 100%;      /* Optional full-width styling */
}

.large-button {
  font-size: 1.5em; /* Doubles the font size */
  padding: 10px 20px; /* Adds padding for a larger button look */
  margin: 10px;       /* Adds some spacing between buttons */
}